/* You can add global styles to this file, and also import other style files */
@import './node_modules/@mt-ng2/styles/styles.less';

@import './common/styles/styles.library.less';

// OVERRIDE styles.less variables
@primary: @buttonColor;
@lightPrimary: #c9a1ff;
@darkPrimary: #761ef0;

// @lightText: @lightPrimary;
// @errorText: #e83849;
// @lightBackground: #ecf0f5;

// @sidebarLiHeaderFontColor: @superLightText;
// @sidebarLiHeaderBackgroundColor: @darkPrimary;
// @sidebarLinkFontColor: @superLightText;
// @tableStripedAltBackgroundColor: #dae7f2;
// END OVERRIDE

.btn-transparent:hover {
    background-color: @buttonColor;
    border-color: @buttonColor;
    color: #fff;
}

/* press-start-2p-regular - latin */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: local('Press Start 2P Regular'), local('PressStart2P-Regular'), url('/assets/fonts/press-start-2p-v8-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/press-start-2p-v8-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* Topsicle */
@font-face {
    font-family: 'Topsicle';
    src: url('/assets/fonts/topsicle-z8dWw.ttf');
    font-display: swap;
}

/* Spankys Bungalow */
@font-face {
    font-family: 'Spankys Bungalow';
    src: url('/assets/fonts/SpankysBungalow-JpWm.ttf');
    font-display: swap;
}

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

:root {
    --primary-background: @backgroundColor;

    --primary-font: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --header-font: 'Press Start 2P', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    --button-font: 'Press Start 2P', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    --button-size: 14px;
    --button-weight: 400;
    --alternative-font-family-header: 'Montserrat', 'Open Sans', 'Segoe UI', sans-serif;
    --primary-font-size: 16px;

    --primary-color: #13020c;
    --navigation-color: white;
    --secondary-color: #1e6e99;
    --secondary-color-shade: #0f374c;
    --secondary-color-tint: #33b8ff;

    --neon-transition: all 0.1s ease;

    --neon-purple-color: #761ef0;
    --neon-purple-text-color: #efddff;
    --neon-purple-text-shadow: 0 0 5px #761ef0, 0 0 5px #761ef0, 0 0 10px #b27aff, 0 0 12px #c9a1ff, 0 0 15px #d8bcff, 0 0 20px #e0c9ff,
        0 0 25px #586cb2;

    --neon-red-text-color: #facfe3;
    --neon-red-text-shadow: 0 0 5px #e82784, 0 0 5px #e82784, 0 0 8px #f287ba, 0 0 12px #f59fc8, 0 0 15px #ade2ff, 0 0 20px #47bfff, 0 0 25px #33b8ff;

    --neon-green-text-color: #ceffc5;
    --neon-green-text-shadow: 0 0 5px #39ff14, 0 0 5px #39ff14, 0 0 8px #7bff62, 0 0 12px #adff9d, 0 0 15px #deffd8, 0 0 20px #fff9d8,
        0 0 25px #ffed8a;

    --neon-yellow-text-color: #f2ffc5;
    --neon-yellow-text-shadow: 0 0 5px #dcff14, 0 0 5px #dcff14, 0 0 8px #e2ff62, 0 0 12px #e2ff9d, 0 0 15px #f9ffd8, 0 0 20px #e3ffd8,
        0 0 25px #ffed8a;

    --neon-pink-color: #e118d6;
    --neon-pink-color-tint: #ea5de2;
    --neon-pink-main-box-shadow: 0 0 5px rgba(225, 24, 214, 0.2), inset 0 0 5px rgba(225, 24, 214, 0.1);
    --neon-pink-alt-box-shadow: 0 0 15px rgba(225, 24, 214, 0.6), inset 0 0 10px rgba(225, 24, 214, 0.4);

    --ql-snow-background: #ffffff;
    --ngb-datePicker-background: #ffffff;
    --selected-date-label-background: #ffffff;

    --input-group-inline-typeahead-border-radius: 20px;

    --ql-editor-padding: 12px 15px;

    .disable-link-look {
        text-decoration: none;
        color: #777;
    }
}

body {
    font: var(--primary-font);
    font-size: var(--primary-font-size, 16px);
}

.wrapper.login {
    --primary-background: #000;
    --primary-color: #9ce5ff;

    app-google-login button {
        background-color: #000;
        font-family: 'Roboto';
        animation: blinking 1337ms infinite;
    }
    @keyframes blinking {
        0% {
            background-color: #9ce5ff;
            box-shadow: 0 0 5px #9ce5ff;
        }
        50% {
            background-color: #c3efff;
            box-shadow: 0 0 10px #c3efff;
            font-weight: 700;
            text-shadow: 0 0 1px #000, 0 0 3px #006588, 0 0 8px #0081b0;
        }
        100% {
            background-color: #9ce5ff;
            box-shadow: 0 0 5px #9ce5ff;
        }
    }
}

.container-fluid {
    background-color: var(--primary-background);

    .login-box-body {
        background-color: var(--primary-background);
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
        color: var(--primary-color);
        font-family: var(--header-font);
        // see all link used in cards h4 tags
        .see-all-link {
            float: right;
            cursor: pointer;
            font-size: 0.7em;
            font-family: sans-serif;
        }
    }
    h5 {
        font-size: 18px;
        font-weight: 700;
    }

    .header {
        height: 50px;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 30px;
        padding-bottom: 10px;
        background-color: var(--primary-color);
    }
}

.btn {
    font-family: var(--button-font, 'Press Start 2P', sans-serif);
    font-size: var(--button-size, inherit);
    font-weight: var(--button-weight, 400);
}

.ql-container .ql-editor {
    font-family: var(--primary-font);
    font-size: 15px;
    padding: var(--ql-editor-padding);
}

.ql-container.ngx-quill-view .ql-editor pre.ql-syntax {
    overflow: scroll visible;
    white-space: pre;
}

// all primary labels to be purple
.label-primary {
    color: #fff;
    background-color: #8860d0;
}
// all secondary labels to be pink
.label-secondary {
    color: #fff;
    background-color: #cb16c1;
}
.label {
    padding-bottom: 0.2em; // fix awkward extra padding on labels
    display: inline-block; // ensure spacing between rows of labels
}

// fixing controls to have white background when not on something that already has a white background
.ql-snow {
    background-color: var(--ql-snow-background);
}
ngb-datepicker {
    background-color: var(--ngb-datePicker-background);
}
.selected-date-label {
    background-color: var(--selected-date-label-background);
}

.input-group.inline-typeahead .form-control:last-child {
    border-radius: var(--input-group-inline-typeahead-border-radius, 0);
}

.comment-text-area {
    padding: 2px 10px 7px 10px;

    &::-webkit-input-placeholder {
        color: rgba(110, 110, 110, 0.4);
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(110, 110, 110, 0.4);
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(110, 110, 110, 0.4);
    }

    &:-ms-input-placeholder {
        color: rgba(110, 110, 110, 0.4);
    }

    &::placeholder {
        color: rgba(110, 110, 110, 0.4);
    }
}

code,
pre {
    background-color: black !important;
    color: white !important;
}

a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

.mat-tooltip.tool-tip {
    font-size: 16px;
    margin: 4px;
}

.calendar-event-selected span {
    font-weight: bold;
}

.margin-bottom {
    margin-bottom: 1em;
}

.margin-top {
    margin-top: 1em;
}

.margin-left {
    margin-left: 1em;
}

mt-search-filter-daterange {
    .dropdown-menu {
        .btn {
            font-size: 12px;
        }
        .start-date,
        .end-date {
            .btn.selected-date-label {
                font-size: 14px;
                font-family: sans-serif;
            }
        }
    }
}
